
import { defineComponent, reactive, ref, toRef, toRefs } from "vue";
import { usePicker, PickerType, Picker, UfUploadFileType, useDate } from "@/composables";
import { userService } from "@/service";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";
import validate from "@/utils/validate";
const getDefaultForm = () => ({
  name: "",
  mobile: "",
  email: "",
  id_card: "",
  factory_name: "",
  social_credit: "",
  province: "",
  city: "",
  county: "",
  address: "",
  contact_name: "",
  contact_phone: ""
});

interface Certificate {
  id: number;
  certificateName: string;
  certificateTypePicker: Picker<"Single">;
  certificateFile: UfUploadFileType[];
}

interface DataProps {
  formData: ReturnType<typeof getDefaultForm>;
  idcardData: UfUploadFileType[];
  certificates: Certificate[];
  daterangePickerIsShow: boolean;
  daterangePickerData: Date[];
  daterangePicker2IsShow: boolean;
  daterangePicker2Data: Date[];
  licenseFile: UfUploadFileType[];
}

interface ErrorInfo<T> {
  values: T;
  errors: {
    name: string;
    message: string;
  }[];
}

export default defineComponent({
  name: "FactoryAuth",
  setup() {
    const router = useRouter();
    const { picker: genderPicker } = usePicker(PickerType.Single.GENDER);
    const { daterangePickerDateFormat } = useDate();
    const area = ref("");

    const factoryData = reactive<DataProps>({
      formData: getDefaultForm(),
      idcardData: [],
      certificates: [],
      daterangePickerIsShow: false,
      daterangePickerData: [],
      daterangePicker2IsShow: false,
      daterangePicker2Data: [],
      licenseFile: []
    });
    const addCertificate = () => {
      const { picker } = usePicker(PickerType.Single.CERTIFICATE_TYPE);
      factoryData.certificates.push({
        id: new Date().getTime(),
        certificateName: "",
        certificateTypePicker: picker,
        certificateFile: []
      });
    };
    const removeCertificate = (index: number) => {
      factoryData.certificates.splice(index, 1);
    };
    const validityOfIDCard = daterangePickerDateFormat(toRef(factoryData, "daterangePickerData"));
    const validityOfFactory = daterangePickerDateFormat(toRef(factoryData, "daterangePicker2Data"));
    const onSubmit = async (values: DataProps["formData"]) => {
      const certificate = factoryData.certificates
        .filter(certificate => certificate.certificateFile.length !== 0)
        .map(certificate => ({
          type_id: certificate.certificateTypePicker.value,
          photo_id: certificate.certificateFile[0].res.id
        }));
      try {
        await userService.authentication({
          type: "7",
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          gender: genderPicker.value,
          positive: factoryData.idcardData[0]?.res.id,
          back: factoryData.idcardData[1]?.res.id,
          id_card: values.id_card,
          start_time: validityOfIDCard.value.startTimestamp,
          end_time: validityOfIDCard.value.endTimestamp,
          factory_name: values.factory_name,
          license: factoryData.licenseFile[0]?.res.id,
          social_credit: values.social_credit,
          license_start_time: validityOfFactory.value.startTimestamp,
          license_end_time: validityOfFactory.value.endTimestamp,
          province: area.value.split(",")[0],
          city: area.value.split(",")[1],
          county: area.value.split(",")[2],
          address: values.address,
          contact_name: values.contact_name,
          contact_phone: values.contact_phone,
          certificate
        });
        await router.push({
          name: RouterName.AUTHENTICATION_DETAIL
        });
      } catch (error) {}
    };

    const onFailed = (errorInfo: ErrorInfo<DataProps["formData"]>) => {
      Toast.fail(errorInfo.errors[0].message);
    };

    addCertificate();

    return {
      ...toRefs(factoryData),
      onSubmit,
      onFailed,
      genderPicker,
      area,
      addCertificate,
      removeCertificate,
      validityOfIDCard,
      validityOfFactory,
      validate
    };
  }
});
